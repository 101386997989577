import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  FormGroup,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import Select from 'react-select'
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"

function Partnermore() {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const history = useHistory()
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [show, setshow] = useState(false)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const [inputList, setInputList] = useState([{ title: "", value: "" }])
  const [inputList1, setInputList1] = useState([{ title: "" }])
  console.log(inputList)
  console.log(inputList1)

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
    console.log(index)
  }
  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList1]
    list[index][name] = value
    setInputList1(list)
    console.log(index)
  }
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }
  const handleRemoveClick1 = index => {
    const list = [...inputList1]
    list.splice(index, 1)
    setInputList1(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { title: "", value: "" }])
  }
  const handleAddClick1 = () => {
    setInputList1([...inputList1, { title: "" }])
  }

  //   const [form, setbanner] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState([])
  const [banks, setbanks] = useState([])
  const [Files, setFiles] = useState({ flatImages: "" })
  const [Files1, setFiles1] = useState({ siteImage: "" })
  const [Files2, setFiles2] = useState({ image3d: "" })

  const [ims, setims] = useState([])
  // const [mssag, setmssag] = useState([])

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }
  const changeHandler2 = e => {
    setFiles2(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange2 = async () => {
    // let myUser = { ...form }
    // myUser[e.target.name] = e.target.value
    // setform(myUser)
    const bodydata = {
      productId: "6474557f4e900cde632eea93",
    }
    const resonse = await addData("admin/subproduct/getallactive", bodydata)
    var _data = resonse
    setcategory(_data.data.subProduct)
  }

  const getAllbanks = async () => {
    const resonse = await addData("admin/realestate/features/getallactive")
    var _data = resonse
    setbanks(_data.data.feature)
  }

  const banksid = banks.map((data) => (
    { value: data._id, label: data.title }
  ))

  const getAllbenners = async () => {
    const bodydata = {
      id: sessionStorage.getItem("realestateid")
    }
    const resonse = await addData("admin/realestate/getbyid", bodydata)
    var _data = resonse
    setform(_data.data.realestate)

    setims(_data.data.realestate.images)
    setvalue0(_data.data.realestate.approxEMI)
    // setInputList(_data.data.realestate.amenities)
    // setInputList1(_data.data.realestate.highlights)
    // setselectedMulti1(_data.data.realestate.features.map((data) => ({ label: data.title, value: data._id })));

    // const selectedOptions = _data.data.realestate.features.map((data) => ({
    //   label: data.title,
    //   value: data._id
    // }));
    // setselectedMulti1(selectedOptions);
    // console.log(selectedOptions)

    // setselectedMulti1(_data.realestate.features.map((data) => ({ label: data.title, value: data._id })));

  }


  const [selectedMulti1, setselectedMulti1] = useState([])
  console.log(selectedMulti1)

  function handleMulti(data) {
    setselectedMulti1(data)
  }

  const [value0, setvalue0] = useState([])
  console.log(value0)

  const handleChange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    // const totalemi = (Number(e.target.value) * Number(form.approxEMIpercentage)) / 100
    // setvalue0(totalemi)
    const yearst = (Number(form.approxEMIpercentage) / 100) / 12
    const totalemi = Number(e.target.value) * Number(yearst) * (Math.pow(1 + Number(yearst), 12) / (Math.pow(1 + Number(yearst), 12) - 1))
    setvalue0(totalemi.toFixed(0))
  }

  const handleChange0 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const yearst = (Number(e.target.value) / 100) / 12
    const totalemi = Number(form.amount) * Number(yearst) * (Math.pow(1 + Number(yearst), 12) / (Math.pow(1 + Number(yearst), 12) - 1))
    setvalue0(totalemi.toFixed(0))
  }

  useEffect(() => {
    getAllbanks()
    handleChange2()
    getAllbenners()
  }, [])

  const addbenners = async (e) => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("productId", "6474557f4e900cde632eea93")
    dataArray.append("subProductId", form.subProductId)
    dataArray.append("title", form.title)
    dataArray.append("amount", form.amount)
    dataArray.append("approxEMI", form.approxEMI)
    dataArray.append("approxEMIpercentage", form.approxEMIpercentage)
    dataArray.append("areaName", form.areaName)
    dataArray.append("description", form.description)
    dataArray.append("builtUpArea", form.builtUpArea)
    dataArray.append("furnishing", form.furnishing)
    dataArray.append("apartmentType", form.apartmentType)
    dataArray.append("dimension", form.dimension)
    dataArray.append("facing", form.facing)
    dataArray.append("propertyStatus", form.propertyStatus)
    dataArray.append("propertyType", form.propertyType)
    dataArray.append("propertyAge", form.propertyAge)
    dataArray.append("parking", form.parking)
    dataArray.append("discount", form.discount)
    dataArray.append("availability", form.availability)
    dataArray.append("totalFloors", form.totalFloors)
    dataArray.append("floor", form.floor)
    dataArray.append("status", form.status)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("images", Files[i])
    }
    // for (let i = 0; i < Files1.length; i++) {
    //   dataArray.append("siteImage", Files1[i])
    // }
    // for (let i = 0; i < Files2.length; i++) {
    //   dataArray.append("image3d", Files2[i])
    // }

    try {
      const resonse = await addData("/admin/realestate/edit/" + sessionStorage.getItem("realestateid"), dataArray)
      var _data = resonse
      console.log(_data)
      // toast.success(_data.data.message)
      getAllbenners()
      setFiles({ images: "" })
      // setFiles1({siteImage:""})
      // setFiles2({image3d:""})
      // setInputList({ title: "", value: "" })
      // setInputList1({ title: ""})
      sessionStorage.setItem("tost","Realestate has been Updated successfully")
      history.push("/realestate")
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Right Agent Group" breadcrumbItem="Real Estate" />

          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Edit Real Estate</h4>
                  <form onSubmit={(e) => { addbenners(e) }}>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ms-2">02</span> Key
                              Features
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            <div>
                              <Row>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Category{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-select"
                                    name="subProductId"
                                    value={form.subProductId}
                                  >
                                    <option value="">Select</option>
                                    {category.map((data, key) => (
                                      <option key={key} value={data._id}>
                                        {data.subProduct}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Name <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Name"
                                    name="title"
                                    value={form.title}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Price <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange1(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Price"
                                    name="amount"
                                    type="number"
                                    value={form.amount}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Approx Emi Percentage <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Approx Emi"
                                    name="approxEMIpercentage"
                                    type="text"
                                    value={form.approxEMIpercentage}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Approx Emi <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                   onChange={e => {
                                    handleChange(e)
                                  }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Approx Emi"
                                    name="approxEMI"
                                    type="number"
                                    value={form.approxEMI}
                                  />
                                </div>
                                {/* <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Area Name <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Area Name"
                                    name="areaName"
                                    type="text"
                                  />
                                </div> */}
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Total Area <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Total Area"
                                    name="builtUpArea"
                                    type="text"
                                    value={form.builtUpArea}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Furnishing <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    value={form.furnishing}
                                    required
                                    name="furnishing"
                                    className="form-select">
                                    <option value="">Select</option>
                                    <option value="Full">Full</option>
                                    <option value="Semi">Semi</option>
                                    <option value="None">None</option>
                                  </select>

                                </div>
                                {/* <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Property Type <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    value={form.apartmentType}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Property Type"
                                    name="apartmentType"
                                    type="text"
                                  />
                               
                                </div> */}
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Dimension <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Dimension"
                                    name="dimension"
                                    type="text"
                                    value={form.dimension}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Facing <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Facing"
                                    name="facing"
                                    type="text"
                                    value={form.facing}
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Row>
                                    <Col>
                                      <Label for="basicpill-firstname-input1">
                                        Images{" "}
                                        <span className="text-danger">*</span>
                                      </Label></Col>
                                    <Col className="text-end">
                                      <div onClick={toggle} className="text-danger">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Input
                                    type="file"
                                    multiple
                                    onChange={changeHandler}

                                    className="form-control"
                                    placeholder="Enter Price"
                                    name="images"
                                  />
                                </div>
                                {/* <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Site Map
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    onChange={changeHandler1}
                                    required
                                    className="form-control"
                                    placeholder="Enter Price"
                                    name="siteImage"
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    3d Image
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={changeHandler2}
                                    type="file"
                                    required
                                    className="form-control"
                                    placeholder="Enter Price"
                                    name="image3d"
                                  />
                                </div> */}
                                <div className="col-lg-12 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Description{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <textarea
                                    type="file"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Description"
                                    name="description"
                                    value={form.description}
                                  />
                                </div>
                              </Row>
                            </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div div className="row">
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Property Status <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  value={form.propertyStatus}
                                  required
                                  name="propertyStatus"
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="New_Launch">New Launch</option>
                                  <option value="Under_Construction">Under Construction</option>
                                  <option value="Ready_To_Move">Ready To Move</option>
                                </select>
                              </div>

                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Property Type <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  value={form.apartmentType}
                                  required
                                  name="apartmentType"
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="1BHK">1BHK</option>
                                  <option value="2BHK">2BHK</option>
                                  <option value="3BHK">3BHK</option>
                                  <option value="4+BHK">4+BHK</option>
                                  <option value="1RK">1RK</option>
                                </select>
                              </div>

                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Property <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  value={form.propertyType}
                                  required
                                  name="propertyType"
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="Gated_Community">Gated Community</option>
                                  <option value="Villas">Villas</option>
                                  <option value="Plots">Plots</option>
                                  <option value="Residential_Plots">Residential Plots</option>
                                  <option value="Commercial_Plots">Commercial Plots</option>
                                  <option value="Agricultural_Lands">Agricultural Lands</option>
                                  <option value="Builder_Development_Land">Builder Development Land</option>
                                  <option value="Material_Contractor">Material Contractor</option>
                                  <option value="Lease_Property">Lease Property</option>
                                  <option value="Built_Up_Area">Built Up Area</option>
                                  <option value="Indipendent_House">Indipendent House</option>
                                  <option value="Builder_Development_Flats">Builder_Development_Flats</option>
                                  <option value="Development_Lands">Development Lands</option>
                                  <option value="Appartments">Appartments</option>
                                  <option value="New_Launching">New Launching</option>
                                  <option value="Under_Constraction">Under Constraction</option>
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Parking <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  value={form.parking}
                                  required
                                  name="parking"
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="2_Wheeler">2 Wheeler</option>
                                  <option value="4_Wheeler">4 Wheeler</option>
                                  <option value="2_&_4_Wheeler">2 & 4 Wheeler</option>
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Total Floors <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="number"
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  className="form-control"
                                  placeholder="Enter Total Floor Number"
                                  name="totalFloors"
                                  value={form.totalFloors}
                                />
                              </div>
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Floor <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="number"
                                  value={form.floor}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  className="form-control"
                                  placeholder="Enter Floor Number"
                                  name="floor"
                                />

                              </div>
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Property Age <span className="text-danger">*</span>
                                </Label>
                                <input
                                  type="number"
                                  placeholder="Enter Property Age"
                                  value={form.propertyAge}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  name="propertyAge"
                                  className="form-control" />
                                {/* <Label for="basicpill-firstname-input1">
                                  Property Age <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  name="propertyAge"
                                  value={form.propertyAge}
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="1_Year">Less Then 1 Years</option>
                                  <option value="3_Year">Less Then 3 Years</option>
                                  <option value="5_Year">Less Then 5 Years</option>
                                  <option value="10_Year">Less Then 10 Years</option>
                                </select> */}
                              </div>

                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Availability <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  value={form.availability}
                                  required
                                  name="availability"
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="Immediate">Immediate</option>
                                  <option value="Within_15_Days">Within 15 Days</option>
                                  <option value="Within_30_Days">Within 30 Days</option>
                                  <option value="After_30_Days">After 30 Days</option>
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Disscount <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  name="discount"
                                  value={form.discount}
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="negotiable">Negotiable</option>
                                  <option value="Non_negotiable">Non-negotiable</option>
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Status <span className="text-danger">*</span>
                                </Label>
                                <select
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  name="status"
                                  value={form.status}
                                  className="form-select">
                                  <option value="">Select</option>
                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </select>
                              </div>

                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li>
                            <Link className="bg-danger text-white" to="/realestate">
                              Cancel
                            </Link>
                            {/* <Button
                              color="danger"
                              onClick={() => {
                                setshow(!show)
                              }}
                            >
                              
                            </Button> */}
                          </li>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 2 ? "next d-none" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                          <li
                            className={activeTab !== 2 ? "next d-none" : "next"}
                          >
                            <Button
                              type="submit"
                              color="success"
                            // onClick={() => {
                            //   toggleTab(activeTab + 1)
                            // }}
                            >
                              Submit
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Toaster />
        </Container>

        <Modal className="modal-dialog" isOpen={open}>
          <ModalHeader toggle={toggle}>All Images</ModalHeader>
          <ModalBody className="text-center">

            <Row>
              {ims.map((data, key) => (
                <Col key={key}>
                  <div >
                    <img src={imgUrl + data} style={{ width: "100%", height: "200px" }} />
                  </div>
                </Col>
              ))}
            </Row>

          </ModalBody>
          {/* <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
             <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div> */}
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Partnermore
