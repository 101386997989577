import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  FormGroup,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import Select from 'react-select'
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"

function Partnermore() {
  const history = useHistory()
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [show, setshow] = useState(false)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const [inputList, setInputList] = useState([{ title: "", value: "" }])
  const [inputList1, setInputList1] = useState([{ title: "" }])
  console.log(inputList)
  console.log(inputList1)

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
    console.log(index)
  }
  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList1]
    list[index][name] = value
    setInputList1(list)
    console.log(index)
  }
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }
  const handleRemoveClick1 = index => {
    const list = [...inputList1]
    list.splice(index, 1)
    setInputList1(list)
  }
  const handleAddClick = () => {
    setInputList([...inputList, { title: "", value: "" }])
  }
  const handleAddClick1 = () => {
    setInputList1([...inputList1, { title: "" }])
  }

  const [banner, setbanner] = useState([])
  const [banks, setbanks] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState({
    subProductId: "",
    title: "",
    amount: "",
    approxEMI: "",
    approxEMIpercentage: "",
    description: "",
    builtUpArea: "",
    furnishing: "",
    apartmentType: "",
    dimension: "",
    facing: "",
    propertyStatus: "",
    propertyType: "",
    propertyAge: "",
    parking: "",
    discount: "",
    availability: "",
    floor: "",
    totalFloors: "",
  })
  const [Files, setFiles] = useState({ flatImages: "" })
  const [Files1, setFiles1] = useState({ siteImage: "" })
  const [Files2, setFiles2] = useState({ image3d: "" })

  const [errorObject, seterrorObject] = useState(
    {
      subProductId: "",
      title: "",
      amount: "",
      approxEMI: "",
      approxEMIpercentage: "",
      description: "",
      builtUpArea: "",
      furnishing: "",
      apartmentType: "",
      dimension: "",
      facing: "",
      propertyStatus: "",
      propertyType: "",
      propertyAge: "",
      parking: "",
      discount: "",
      availability: "",
      floor: "",
      totalFloors: "",
    }
  )
  const [er, seter] = useState(
    {
      subProductId: "",
      title: "",
      amount: "",
      approxEMI: "",
      approxEMIpercentage: "",
      description: "",
      builtUpArea: "",
      furnishing: "",
      dimension: "",
      facing: "",
      propertyStatus: "",
      propertyType: "",
      propertyAge: "",
      parking: "",
      discount: "",
      availability: "",
      floor: "",
      totalFloors: "",
    }
  )

  const validateFun = e => {
    console.log(form)
    if (form.subProductId == "") {
      let error = { ...er }
      error["subProductId"] = "Please Sub Product Id"
      seterrorObject(error)
    } else if (form.title == "") {
      let error = { ...er }
      error["title"] = "Please Enter Name"
      seterrorObject(error)
    } else if (form.amount == "") {
      let error = { ...er }
      error["amount"] = "Please Enter Amount"
      seterrorObject(error)
    } else if (form.approxEMIpercentage == "") {
      let error = { ...er }
      error["approxEMIpercentage"] = "Please Enter Approx EMI Percentage"
      seterrorObject(error)

      // } else if (form.areaName == "") {
      //   let error = { ...er }
      //   error["areaName"] = "Please Enter Area Name"
      //   seterrorObject(error)

    } else if (form.builtUpArea == "") {
      let error = { ...er }
      error["builtUpArea"] = "Please Enter Total Area"
      seterrorObject(error)
    } else if (form.furnishing == "") {
      let error = { ...er }
      error["furnishing"] = "Please Select Furnishing"
      seterrorObject(error)

    } else if (form.dimension == "") {
      let error = { ...er }
      error["dimension"] = "Please Enter Dimension"
      seterrorObject(error)
    } else if (form.facing == "") {
      let error = { ...er }
      error["facing"] = "Please Select Facing"
      seterrorObject(error)
    } else if (Files.images == "") {
      let error = { ...er }
      error["images"] = "Please Select Images"
      seterrorObject(error)

    } else if (form.description == "") {
      let error = { ...er }
      error["description"] = "Please Enter description"
      seterrorObject(error)
    }
    //  else if (form.propertyStatus == "") {
    //   let error = { ...er }
    //   error["propertyStatus"] = "Please Select PropertyStatus"
    //   seterrorObject(error)
    // } else if (form.propertyType == "") {
    //   let error = { ...er }
    //   error["propertyType"] = "Please Select PropertyType"
    //   seterrorObject(error)
    // } else if (form.propertyAge == "") {
    //   let error = { ...er }
    //   error["propertyAge"] = "Please Select PropertyAge"
    //   seterrorObject(error)
    // } else if (form.parking == "") {
    //   let error = { ...er }
    //   error["parking"] = "Please Select Parking"
    //   seterrorObject(error)
    // } else if (form.discount == "") {
    //   let error = { ...er }
    //   error["discount"] = "Please Select Discount"
    //   seterrorObject(error)
    // }else if (form.availability == "") {
    //   let error = { ...er }
    //   error["availability"] = "Please Select Availability"
    //   seterrorObject(error)
    // } else if (form.floor == "") {
    //   let error = { ...er }
    //   error["floor"] = "Please Enter Floor"
    //   seterrorObject(error)
    // }else if (form.totalFloors == "") {
    //   let error = { ...er }
    //   error["totalFloors"] = "Please Enter Total Floors"
    //   seterrorObject(error)
    // } 
    else {
      let error = { ...er }
      seterrorObject(error)
      toggleTab(activeTab + 1)
    }
  }



  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }


  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }
  const changeHandler2 = e => {
    setFiles2(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const [value0, setvalue0] = useState([])
  console.log(value0)

  const handleChange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    // const totalemi = (Number(e.target.value) * Number(form.approxEMIpercentage)) / 100
    // setvalue0(totalemi)
    const yearst = (Number(form.approxEMIpercentage) / 100) / 12
    const totalemi = Number(e.target.value) * Number(yearst) * (Math.pow(1 + Number(yearst), 12) / (Math.pow(1 + Number(yearst), 12) - 1))
    setvalue0(totalemi.toFixed(0))
  }

  const handleChange0 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const yearst = (Number(e.target.value) / 100) / 12
    const totalemi = Number(form.amount) * Number(yearst) * (Math.pow(1 + Number(yearst), 12) / (Math.pow(1 + Number(yearst), 12) - 1))
    setvalue0(totalemi.toFixed(0))
  }

  const handleChange2 = async () => {
    // let myUser = { ...form }
    // myUser[e.target.name] = e.target.value
    // setform(myUser)
    const bodydata = {
      productId: "6474557f4e900cde632eea93",
    }
    const resonse = await addData("admin/subproduct/getallactive", bodydata)
    var _data = resonse
    setcategory(_data.data.subProduct)
  }

  const [selectedMulti1, setselectedMulti1] = useState({ label: "", value: "" })

  console.log(selectedMulti1)
  function handleMulti(data) {
    setselectedMulti1(data)
  }

  const getAllbenners = async () => {
    const resonse = await addData("admin/realestate/getall")
    var _data = resonse
    setbanner(_data.data.realestate)
  }

  const getAllbanks = async () => {
    const resonse = await addData("admin/realestate/features/getallactive")
    var _data = resonse
    setbanks(_data.data.feature)
  }

  const banksid = banks.map((data) => (
    { value: data._id, label: data.title }
  ))

  const datass = () => {
        const location = sessionStorage.getItem("tost")
        if (location != "") {
          toast.success(location)
          sessionStorage.clear()
        } else {
          sessionStorage.clear()
        }
      }

  useEffect(() => {
    handleChange2()
    getAllbanks()
    getAllbenners()
    datass()
  }, [])

  const addbenners = async (e) => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("productId", "6474557f4e900cde632eea93")
    dataArray.append("subProductId", form.subProductId)
    dataArray.append("title", form.title)
    dataArray.append("amount", form.amount)
    dataArray.append("approxEMI", Number(form.approxEMI))
    dataArray.append("approxEMIpercentage", form.approxEMIpercentage)
    dataArray.append("areaName", form.areaName)
    dataArray.append("description", form.description)
    dataArray.append("builtUpArea", form.builtUpArea)
    dataArray.append("furnishing", form.furnishing)
    dataArray.append("apartmentType", form.apartmentType)
    dataArray.append("dimension", form.dimension)
    dataArray.append("facing", form.facing)
    dataArray.append("propertyStatus", form.propertyStatus)
    dataArray.append("propertyType", form.propertyType)
    dataArray.append("propertyAge", form.propertyAge)
    dataArray.append("parking", form.parking)
    dataArray.append("discount", form.discount)
    dataArray.append("availability", form.availability)
    dataArray.append("floor", form.floor)
    dataArray.append("totalFloors", form.totalFloors)
    // dataArray.append("amenities", JSON.stringify(inputList))
    // dataArray.append("highlights", JSON.stringify(inputList1))
    // dataArray.append("features", JSON.stringify(selectedMulti1))

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("images", Files[i])
    }
    // for (let i = 0; i < Files1.length; i++) {
    //   dataArray.append("siteImage", Files1[i])
    // }
    // for (let i = 0; i < Files2.length; i++) {
    //   dataArray.append("image3d", Files2[i])
    // }

    try {
      const resonse = await addData("admin/realestate/add", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllbenners()
      setFiles({ images: "" })
      setform(
        {
          subProductId: "",
          title: "",
          amount: "",
          approxEMI: "",
          approxEMIpercentage: "",
          description: "",
          builtUpArea: "",
          furnishing: "",
          apartmentType: "",
          dimension: "",
          facing: "",
          propertyStatus: "",
          propertyType: "",
          propertyAge: "",
          parking: "",
          discount: "",
          availability: "",
          floor: "",
          totalFloors: "",
        }
      )
      setvalue0([])
      // setFiles1({siteImage:""})
      // setFiles2({image3d:""})
      // setInputList({ title: "", value: "" })
      // setInputList1({ title: ""})
      setshow(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  // Search fuction
  const handleSearch = async e => {
    const resonse = await addData("admin/realestate/getall?searchQuery=" + e.target.value)
    var _data = resonse
    setbanner(_data.data.realestate)
  }


  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const editRedirect = (data) => {
    sessionStorage.setItem("realestateid", data._id)
    history.push("/editrealestate")
  }

  // Delete fuction

  const deletebenners = async (data) => {
    try {
      const resonse = await deletedData(
        "admin/realestate/delete/" + data._id,
        {}
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Right Agent Group" breadcrumbItem="Real Estate" />

          <Row>
            <Col lg="12">
              {show == true ? (
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Add Real Estate</h4>
                    <form onSubmit={(e) => { addbenners(e) }}>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}
                            >
                              <NavLink
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                                disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">1.</span> Details
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 2 })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                                disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number ms-2">02</span> Key
                                Features
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix mt-4">
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <div>
                                <Row>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Category{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-select"
                                      name="subProductId"
                                    >
                                      <option value="">Select</option>
                                      {category.map((data, key) => (
                                        <option key={key} value={data._id}>
                                          {data.subProduct}
                                        </option>
                                      ))}
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.subProductId}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Name <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Name"
                                      name="title"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.title}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Price <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange1(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Price"
                                      name="amount"
                                      type="number"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.amount}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Approx Emi Percentage <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange0(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Approx Emi"
                                      name="approxEMIpercentage"
                                      type="text"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.approxEMIpercentage}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Approx Emi <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Approx Emi"
                                      name="approxEMI"
                                      type="number"
                                      
                                      // value={value0}
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.approxEMI}
                                    </small>
                                  </div>
                                  {/* <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Area Name <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Area Name"
                                    name="areaName"
                                    type="text"
                                  />
                                </div> */}
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Total Area <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Total Area"
                                      name="builtUpArea"
                                      type="text"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.builtUpArea}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Furnishing <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      name="furnishing"
                                      className="form-select">
                                      <option value="">Select</option>
                                      <option value="Full">Full</option>
                                      <option value="Semi">Semi</option>
                                      <option value="None">None</option>
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.furnishing}
                                    </small>
                                  </div>
                                  {/* <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                  Property Type <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Property Type"
                                    name="apartmentType"
                                    type="text"
                                  />
                                   <small style={{ color: "#ff0000" }}>
                                    {errorObject.apartmentType}
                                  </small>
                                </div> */}
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Dimension <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Dimension"
                                      name="dimension"
                                      type="text"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.dimension}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Facing <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Facing"
                                      name="facing"
                                      type="text"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.facing}
                                    </small>
                                  </div>
                                  <div className="col-lg-4 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Images{" "}
                                      <span className="text-danger">(jpg, png, jpeg)</span>
                                    </Label>
                                    <Input
                                      type="file"
                                      multiple
                                      onChange={changeHandler}
                                      required
                                      className="form-control"
                                      placeholder="Enter Price"
                                      name="images"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.images}
                                    </small>
                                  </div>
                                  {/* <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Site Map
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    onChange={changeHandler1}
                                    required
                                    className="form-control"
                                    placeholder="Enter Price"
                                    name="siteImage"
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    3d Image
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    onChange={changeHandler2}
                                    type="file"
                                    required
                                    className="form-control"
                                    placeholder="Enter Price"
                                    name="image3d"
                                  />
                                </div> */}
                                  <div className="col-lg-12 mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Description{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <textarea
                                      type="file"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      required
                                      className="form-control"
                                      placeholder="Enter Description"
                                      name="description"
                                    />
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.description}
                                    </small>
                                  </div>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div div className="row">
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Property Status <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    name="propertyStatus"
                                    className="form-select">
                                    <option value="">Select</option>
                                    <option value="New_Launch">New Launch</option>
                                    <option value="Under_Construction">Under Construction</option>
                                    <option value="Ready_To_Move">Ready To Move</option>
                                  </select>
                                  <small style={{ color: "#ff0000" }}>
                                    {errorObject.description}
                                  </small>
                                </div>


                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                  Property Type <span className="text-danger">*</span>
                                  </Label>
                                  <select 
                                   onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  name="apartmentType"
                                  className="form-select">
                                    <option value="">Select</option>
                                    <option value="1RK">1BHK</option>
                                    <option value="2BHK">2BHK</option>
                                    <option value="3BHK">3BHK</option>
                                    <option value="4+BHK">4+BHK</option>
                                  </select>
                                  <small style={{ color: "#ff0000" }}>
                                    {errorObject.apartmentType}
                                  </small>
                                </div>

                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Property <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    name="propertyType"
                                    className="form-select">
                                    <option value="">Select</option>
                                    <option value="Gated_Community">Gated Community</option>
                                    <option value="Villas">Villas</option>
                                    <option value="Plots">Plots</option>
                                    <option value="Residential_Plots">Residential Plots</option>
                                    <option value="Commercial_Plots">Commercial Plots</option>
                                    <option value="Agricultural_Lands">Agricultural Lands</option>
                                    <option value="Builder_Development_Land">Builder Development Land</option>
                                    <option value="Material_Contractor">Material Contractor</option>
                                    <option value="Lease_Property">Lease Property</option>
                                    <option value="Built_Up_Area">Built Up Area</option>
                                    <option value="Indipendent_House">Indipendent House</option>
                                    <option value="Builder_Development_Flats">Builder_Development_Flats</option>
                                    <option value="Development_Lands">Development Lands</option>
                                    <option value="Appartments">Appartments</option>
                                    <option value="New_Launching">New Launching</option>
                                    <option value="Under_Constraction">Under Constraction</option>
                                  </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Parking <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    name="parking"
                                    className="form-select">
                                    <option value="">Select</option>
                                    <option value="2_Wheeler">2 Wheeler</option>
                                    <option value="4_Wheeler">4 Wheeler</option>
                                    <option value="2_&_4_Wheeler">2 & 4 Wheeler</option>

                                  </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Total Floors <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Floors Number"
                                    name="totalFloors"
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Floor <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-control"
                                    placeholder="Enter Floor Number"
                                    name="floor"
                                  />
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Property Age <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="number"
                                    placeholder="Enter Property Age"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    name="propertyAge"
                                    className="form-control" />
                                  {/* <option value="">Select</option>
                                    <option value="1_Year">Less Then 1 Years</option>
                                    <option value="3_Year">Less Then 3 Years</option>
                                    <option value="5_Year">Less Then 5 Years</option>
                                    <option value="10_Year">Less Then 10 Years</option>
                                  </select> */}
                                </div>

                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Availability <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    name="availability"
                                    className="form-select">
                                    <option value="">Select</option>
                                    <option value="Immediate">Immediate</option>
                                    <option value="Within_15_Days">Within 15 Days</option>
                                    <option value="Within_30_Days">Within 30 Days</option>
                                    <option value="After_30_Days">After 30 Days</option>
                                  </select>
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Disscount <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    name="discount"
                                    className="form-select">
                                    <option value="">Select</option>
                                    <option value="negotiable">Negotiable</option>
                                    <option value="Non_negotiable">Non-negotiable</option>
                                  </select>
                                </div>

                                {/* <div>
                                <h5>Amenities</h5>
                                <div className="row p-2 mb-3">
                                   <div className="col-lg-10">
                                   <Label for="name" style={{ color: "black" }}>
                                        Select Features : <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        name="features"
                                        isMulti
                                        // onChange={ (e) => { handleChange1(e) } }
                                        value={selectedMulti1}
                                        onChange={handleMulti}
                                        options={banksid}
                                        required
                                    />
                                   </div>
                                </div>
                              </div>
                              <div>
                                <h5>Amenities</h5>
                                {inputList.map((x, i) => (
                                  <div key={i} className="box row p-3">
                                    <Col md={5}>
                                      <Label
                                        for="name"
                                        style={{ color: "black" }}
                                      >
                                        Title :{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        onChange={(e) => handleInputChange(e, i)}
                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                      />
                                    </Col>
                                    <Col md={5}>
                                      <Label
                                        for="name"
                                        style={{ color: "black" }}
                                      >
                                        Value :{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        onChange={(e) => handleInputChange(e, i)}
                                        type="text"
                                        name="value"
                                        placeholder="Enter Value"
                                      />
                                    </Col>
                                    <Col sm="2" style={{ marginTop: "30px" }}>
                                      <div className="btn-box">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger text-end btn-sm"
                                            style={{ float: "right" }}
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove
                                          </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                          <button
                                            className="btn btn-outline-info btn-sm w-50"
                                            onClick={handleAddClick}
                                          >
                                            Add
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                ))}
                              </div>
                              <div>
                                <h5>Highlights</h5>
                                {inputList1.map((x, i) => (
                                  <div key={i} className="box row p-3">
                                    <Col md={10}>
                                      <Label
                                        for="name"
                                        style={{ color: "black" }}
                                      >
                                        Title :{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        onChange={e => handleInputChange1(e, i)}
                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                      />
                                    </Col>
                                    <Col sm="2" style={{ marginTop: "30px" }}>
                                      <div className="btn-box">
                                        {inputList1.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger text-end btn-sm"
                                            style={{ float: "right" }}
                                            onClick={() =>
                                              handleRemoveClick1(i)
                                            }
                                          >
                                            Remove
                                          </button>
                                        )}
                                        {inputList1.length - 1 === i && (
                                          <button
                                            className="btn btn-outline-info btn-sm w-50"
                                            onClick={handleAddClick1}
                                          >
                                            Add
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                ))}
                              </div> */}


                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li>
                              <Button
                                color="danger"
                                onClick={() => {
                                  setshow(!show)
                                }}
                              >
                                Cancel
                              </Button>
                            </li>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab === 2 ? "next d-none" : "next"}
                            >
                              <Link
                                to="#"
                                // onClick={() => {
                                //   toggleTab(activeTab + 1)
                                // }}
                                onClick={e => {
                                  validateFun(e)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                            <li
                              className={activeTab !== 2 ? "next d-none" : "next"}
                            >
                              <Button
                                type="submit"
                                color="success"
                              // onClick={() => {
                              //   toggleTab(activeTab + 1)
                              // }}
                              >
                                Submit
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}

              <Card>
                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <Row>
                        <Col md="6">
                          {/* {permissioins.customerAdd === true || roles === "admin" ? ( */}
                          <Button
                            onClick={() => {
                              setshow(!show)
                            }}
                            color="primary"
                          >
                            Add New <i className="fas fa-plus-circle"></i>
                          </Button>
                        </Col>
                        <Col md="6">
                          <div style={{ float: "right" }}>
                            <Input
                              type="text"
                              name="search"
                              onChange={handleSearch}
                              className="form-control"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th> Category Name</th>
                            <th> Name</th>
                            <th> Image</th>
                            <th> Amount</th>
                            <th>Approx EMI</th>

                            <th>Status</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 5 + key + 6}</td>
                              <td>{data.subProduct} </td>
                              <td>{data.title}</td>

                              <td>
                                <img
                                  style={{ width: "60px" }}
                                  src={imgUrl + data.images[0]}
                                />
                              </td>
                              <td>₹ {data.amount} </td>
                              <td>₹ {data.approxEMI} </td>
                              <td>{data.status}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    editRedirect(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                {/* <Link to="/partnermore">
                                <Button
                            
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="warning"
                                  outline
                                >
                                  <i className="bx bx-info-circle"></i>
                                </Button>
                                </Link> */}

                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Toaster />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Partnermore
