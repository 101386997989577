import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"

const Loans = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [addshow, setaddshow] = useState(false)
  const [banner, setbanner] = useState([])
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  console.log(form1)
  const [form2, setform2] = useState([])
  const [Files, setFiles] = useState({ image: "" })
  const [Files1, setFiles1] = useState({ image: "" })

  const history = useHistory()

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const allsubcatids = async (data) => {
    const bodydata = {
      productId: data.productId,
    }
    const resonse = await addData("admin/subproduct/getallactive", bodydata)
    var _data = resonse
    setcategory(_data.data.subProduct)
  }


  const handleChange2 = async e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const bodydata = {
      productId: e.target.value,
    }
    const resonse = await addData("admin/subproduct/getallactive", bodydata)
    var _data = resonse
    setcategory(_data.data.subProduct)
  }

  const handleChange21 = async e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
    const bodydata = {
      productId: e.target.value || form1.productId,
    }
    const resonse = await addData("admin/subproduct/getallactive", bodydata)
    var _data = resonse
    setcategory(_data.data.subProduct)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const [items, setItems] = useState([])
  const [userinfo, setuserinfo] = useState([])
  console.log(items.token)
  console.log(userinfo)

  // get all function

  const getAllbenners = async () => {
    const resonse = await addData("admin/subnextproduct/getall")
    var _data = resonse
    setbanner(_data.data.subNextProduct)
  }

  const getAlltypes = async () => {
    const resonse = await addData("admin/product/getactive", {})
    var _data = resonse.data.productResult
    settypes(_data.slice(1, 3))
  }

  useEffect(() => {
    getAlltypes()
    getAllbenners()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  // Add function

  const addbenners = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("description", form.description)
    dataArray.append("productId", form.productId)
    dataArray.append("subProductId", form.subProductId)
    dataArray.append("seoTitle", form.seoTitle)
    dataArray.append("seoDescription", form.seoDescription)
    dataArray.append("seoKeys", form.seoKeys)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    try {
      const resonse = await addData("admin/subnextproduct/add", dataArray)
      var _data = resonse
      toast.success(_data.data.message)
      setaddshow(false)
      setFiles({ image: "" })
      setform({
        name: "",
        productId: "",
        subProductId: "",
        description: "",
      })
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  // Edit fuction
  const editbenners = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("description", form1.description)
    dataArray.append("productId", form1.productId)
    dataArray.append("subProductId", form1.subProductId)
    dataArray.append("seoTitle", form1.seoTitle)
    dataArray.append("seoDescription", form1.seoDescription)
    dataArray.append("seoKeys", form1.seoKeys)
    dataArray.append("status", form1.status)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }
    try {
      const resonse = await addData(
        "admin/subnextproduct/edit/" + form1._id,
        dataArray
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1({
        name: "",
        description: "",
        productId: "",
        subProductId: "",
      })
      setFiles1({ image: "" })
      setmodal_small(false)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  // Delete fuction
  const deletebenners = async data => {
    try {
      const resonse = await deletedData(
        "admin/subnextproduct/delete/" + data._id,
        {}
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = (data) => {
    setform1(data)
    allsubcatids(data)
    tog_small()
  }

  const [forms, setforms] = useState([])
  console.log(forms)

  // Search fuction
  const handleSearch = async e => {
    const resonse = await addData(
      "admin/subnextproduct/getall?searchQuery=" + e.target.value
    )
    var _data = resonse
    setbanner(_data.data.subNextProduct)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Right Agent Group"
            breadcrumbItem="Loans & Insurances"
          />
          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            {addshow == true ? (

              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Add Loan & Insurance</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        addbenners(e)
                      }}
                    >
                      <Row>
                        <div className="col-md-3 mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type <span className="text-danger">*</span>
                          </Label>
                          <select
                            name="productId"
                            value={form.productId}
                            onChange={e => {
                              handleChange2(e)
                            }}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {types.map((data, key) => (
                              <option key={key} value={data._id}>
                                {data.product}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            Category <span className="text-danger">*</span>
                          </Label>
                          <select
                            onChange={e => {
                              handleChange(e)
                            }}
                            required
                            className="form-select"
                            name="subProductId"
                            value={form.subProductId}
                          >
                            <option value="">Select</option>
                            {category.map((data, key) => (
                              <option key={key} value={data._id}>
                                {data.subProduct}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Name"
                            required
                            pattern="^[a-zA-Z ]*$"
                            name="name"
                            value={form.name}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            Image <span className="text-danger">(80 × 80 px)</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Image"
                            required
                            name="image"
                            value={Files.image}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            name="description"
                            value={form.description}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            SEO Title <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter SEO Title"
                            required
                            name="seoTitle"
                            value={form.seoTitle}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            SEO Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter SEO Description"
                            required
                            name="seoDescription"
                            value={form.seoDescription}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-3">
                          <Label for="basicpill-firstname-input1">
                            SEO Keys <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter SEO Keys"
                            required
                            name="seoKeys"
                            value={form.seoKeys}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Row>

                      <div className="mt-4" style={{ float: "right" }}>
                        <Button onClick={() => { setaddshow(!addshow) }} className="m-1" color="danger" type="button">
                          Cancel <i className="fas fa-times-circle"></i>
                        </Button>
                        <Button className="m-1" color="primary" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>

                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

            ) : ""}
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Loans & Insurances List</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <Row>
                        <Col md="6">
                          <div>
                            <Button onClick={() => { setaddshow(!addshow) }} color="primary">Add Loan & Insurance <i className="bx bx-plus-circle" /></Button>
                          </div>
                        </Col>
                        <Col md="6">
                          <div style={{ float: "right" }}>
                            <Input
                              type="text"
                              name="search"
                              onChange={handleSearch}
                              className="form-control"
                              placeholder="Search.."
                            />
                          </div>
                        </Col>
                      </Row>


                      <Table className="table table-bordered mb-4 mt-3">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Type</th>
                            <th>Category</th>
                            <th> Name</th>
                            <th> Image</th>
                            <th>Status</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 5 + key + 6}</td>
                              <td>{data.product} </td>
                              <td>{data.subProduct} </td>
                              <td>{data.name} </td>
                              <td>
                                <img
                                  style={{ width: "100px" }}
                                  src={imgUrl + data.image}
                                />
                              </td>
                              <td>{data.status}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          // size="sm"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Loans & Insurances
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editbenners(e)
              }}
            >
              <Row>
                <div className="col-md-6 mb-3">
                  <Label for="basicpill-firstname-input1">
                    Type <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="productId"
                    value={form1.productId}
                    onChange={e => {
                      handleChange21(e)
                    }}
                    required
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {types.map((data, key) => (
                      <option key={key} value={data._id}>
                        {data.product}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1">
                    Category <span className="text-danger">*</span>
                  </Label>
                  <select
                    onChange={e => {
                      handleChange1(e)
                    }}
                    required
                    className="form-select"
                    name="subProductId"
                    value={form1.subProductId}
                  >
                    {/* <option value={form1.subProductId}>{form1.subProduct}</option> */}
                    <option value="">Select</option>
                    {category.map((data, key) => (
                      <option key={key} value={data._id}>
                        {data.subProduct}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1">
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Name"
                    required
                    name="name"
                    pattern="^[a-zA-Z ]*$"
                    value={form1.name}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1"> Image  <span className="text-danger">(80 × 80 px)</span></Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="bannerImage"
                    // value={form1.bannerImage}
                    onChange={changeHandler1}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input3">
                    Status <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="status"
                    value={form1.status}
                    onChange={e => {
                      handleChange1(e)
                    }}
                    className="form-select"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">In Active</option>
                  </select>
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                    name="description"
                    value={form1.description}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1">
                    SEO Title <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter SEO Title"
                    required
                    name="seoTitle"
                    value={form1.seoTitle}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1">
                    SEO Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter SEO Description"
                    required
                    name="seoDescription"
                    value={form1.seoDescription}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label for="basicpill-firstname-input1">
                    SEO Keys <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter SEO Keys"
                    required
                    name="seoKeys"
                    value={form1.seoKeys}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>

              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Loans
