import React, { useEffect, useState, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  FormGroup,
  InputGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import { URL } from "../../Apiurls"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"

import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import toast, { Toaster } from "react-hot-toast"
import { addData } from "Servicescalls"
import { imgUrl } from "Baseurls"
// import ReactPaginate from 'react-paginate';
// import toast, { Toaster } from 'react-hot-toast';
// import barcode from "../../assets/images/letast/barcode.jpg"
// import Barcode from "react-barcode";
import { CSVLink } from "react-csv"
import { useReactToPrint } from "react-to-print"
import { format } from "date-fns";

const InsuranceForm = () => {
  const history = useHistory()
  const [customer, setCustomer] = useState([])

  console.log(customer)


  // get all

  const getAllCustomer = async () => {
    const bodydata = {
      id: sessionStorage.getItem("insuranceid")
    }
    const resonse = await addData("admin/insuranceform/getbyid", bodydata)
    var _data = resonse
    setCustomer(_data.data.insuranceForm)
  }

  useEffect(() => {
    getAllCustomer()
  }, [])

  const downloadImage1 = (data) => {
    saveAs(imgUrl + data.zipFile)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Right Agent Group" breadcrumbItem="Insurance View" />

          <Row className="mb-3">
            <Col md="12">

              <div>
                <Button onClick={() => { history.goBack() }} color="primary" outline style={{ float: "right", width: "" }}>Back <i className="bx bx-left-arrow-alt"></i></Button>
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Insurance: </b> <br /> <span style={{ fontSize: "16px" }}>{customer.insuranceName}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Name: </b> <br /> <span style={{ fontSize: "16px" }}>{customer.name}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Phone : </b> <br /><span style={{ fontSize: "16px" }}>{customer.mobile}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Mail : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.eamil}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Qualification : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.qualification}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Date Of Birth : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.dateOfBirth}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Birth of Place : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.birthPlace}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Company Name :</b>  <br /> <span style={{ fontSize: "16px" }}>{customer.workingCompany}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Designation : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.designation}</span></span></Col>
                    {customer.budgetRange == "" ? (
                      ""
                    ) : (
                      <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>BudgetRange: </b> <br /> <span style={{ fontSize: "16px" }}>{customer.budgetRange}</span></span></Col>
                    )}
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>ServiceLength: </b> <br /> <span style={{ fontSize: "16px" }}>{customer.serviceLength}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Annual Income : </b>   <br /><span style={{ fontSize: "16px" }}>{customer.annualIncome}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Marital Status : </b> <br /> <span style={{ fontSize: "16px" }}>{customer.maritalStatus}</span></span></Col>
                    {customer.maritalStatus == "Single" || customer.maritalStatus == "Divorced" ? (
                      ""
                    ) : (
                      <>
                        <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Spouse Name : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.spouseName}</span></span></Col>
                        <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Spouse DoB : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.spouseDoB}</span></span></Col>
                      </>
                    )}

                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Child Policy : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.childName == "" || customer.childName == undefined ? "No" : "Yes"}</span></span></Col>
                    {customer.childName == "" || customer.childName == undefined ? (
                      ""
                    ) : (
                      <>
                        <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Child Name : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.childName}</span></span></Col>
                        <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Child DoB :</b>  <br /> <span style={{ fontSize: "16px" }}>{customer.childDoB}</span></span></Col>
                      </>
                    )}

                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Father Name : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.fatherName}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Mother Name : </b> <br /> <span style={{ fontSize: "16px" }}>{customer.motherName}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>State : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.state}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>City : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.city}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Pin Code : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.pinCode}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Nominee Name : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.nomineeName}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Nominee Relation : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.nomineeRelation}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Type Of Income : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.typeOfIncome}</span></span></Col>
                    <Col className="mb-4" md="3"> <span><b className="text-muted" style={{ fontSize: "16px" }}>Communication Address   : </b>  <br /><span style={{ fontSize: "16px" }}>{customer.communicationAddress}</span></span></Col>
                  </Row>
                  <h5 className="mt-3 mb-4">Documents</h5>

                  <Row>
                    {customer.photo == "" || customer.photo == undefined ? (
                      ""
                    ) : (
                      <Col className="mb-5" md="4">
                        <p>Photo</p>
                        <iframe src={imgUrl + customer.photo} style={{ width: "80%", height: "100%" }} />
                      </Col>
                    )}

                    {customer.aadhar == "" || customer.aadhar == undefined ? (
                      ""
                    ) : (
                      <Col className="mb-5" md="4">
                        <p>Aadhar Card</p>
                        <iframe src={imgUrl + customer.aadhar} style={{ width: "90%", height: "100%" }} />
                      </Col>
                    )}

                    {customer.pan == "" || customer.pan == undefined ? (
                      ""
                    ) : (
                      <Col className="mb-5" md="4">
                        <p>Pan Card</p>
                        <iframe src={imgUrl + customer.pan} style={{ width: "90%", height: "100%" }} />
                      </Col>
                    )}

                    {customer.cancelledCheque == "" || customer.cancelledCheque == undefined ? (
                      ""
                    ) : (
                      <Col className="mb-5" md="4">
                        <p>Cancelled Cheque</p>
                        <iframe src={imgUrl + customer.cancelledCheque} style={{ width: "90%", height: "100%" }} />
                      </Col>
                    )}

                    {customer.payslip == "" || customer.payslip == undefined ? (
                      ""
                    ) : (
                      <Col className="mb-5" md="4">
                        <p>Payslips</p>
                        <iframe src={imgUrl + customer.payslip} style={{ width: "90%", height: "100%" }} />
                      </Col>
                    )}

                  </Row>

                  <div>
                    <Button onClick={() => { downloadImage1(customer) }} color="primary" outline style={{ float: "right", width: "150px" }}>Download <i className="bx bx-cloud-download"></i></Button>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>


        </div>
      </div>
    </React.Fragment>
  )
}

export default InsuranceForm





// const dataArray = new FormData();
// dataArray.append("insuranceName", form.name);
// dataArray.append("name", forms1.name);
// dataArray.append("mobile", forms1.mobile);
// dataArray.append("eamil", forms1.eamil);
// dataArray.append("otp", forms1.otp);
// dataArray.append("qualification", forms1.qualification);
// dataArray.append("birthPlace", forms1.birthPlace);
// dataArray.append("workingCompany", forms1.workingCompany);
// dataArray.append("designation", forms1.designation);
// dataArray.append("budgetRange", forms1.budgetRange);
// dataArray.append("serviceLength", forms1.serviceLength);
// dataArray.append("annualIncome", forms1.annualIncome);
// dataArray.append("spouseName", forms1.spouseName);
// dataArray.append("spouseDoB", forms1.spouseDoB);
// dataArray.append("childName", forms1.childName);
// dataArray.append("childDoB", forms1.childDoB);
// dataArray.append("fatherName", forms1.fatherName);
// dataArray.append("motherName", forms1.motherName);
// dataArray.append("maritalStatus", forms1.maritalStatus);
// dataArray.append("occupation", forms1.occupation);
// dataArray.append("state", forms1.state);
// dataArray.append("city", forms1.city);
// dataArray.append("pinCode", forms1.pinCode);
// dataArray.append("nomineeName", forms1.nomineeName);
// dataArray.append("nomineeRelation", forms1.nomineeRelation);
// dataArray.append("dateOfBirth", forms1.dateOfBirth);
// dataArray.append("typeOfIncome", forms1.typeOfIncome);