import React, { useEffect, useState, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Input,
    Button,
    Table,
    Label,
    Form,
    Modal,
    FormGroup,
    InputGroup,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import { URL } from "../../Apiurls"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"

import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import toast, { Toaster } from "react-hot-toast"
import { addData } from "Servicescalls"
import { imgUrl } from "Baseurls"
// import ReactPaginate from 'react-paginate';
// import toast, { Toaster } from 'react-hot-toast';
// import barcode from "../../assets/images/letast/barcode.jpg"
// import Barcode from "react-barcode";
import { CSVLink } from "react-csv"
import { useReactToPrint } from "react-to-print"
import { format } from "date-fns"
import { saveAs } from "file-saver"

const InsuranceForm = () => {
    const history = useHistory()
    const [customer, setCustomer] = useState([])

    // get all

    const getAllCustomer = async () => {
        const bodydata = {
            id: sessionStorage.getItem("loanobjctid"),
        }
        const resonse = await addData("admin/applyloan/getbyid", bodydata)
        var _data = resonse
        setCustomer(_data.data.applyLoan)
    }

    useEffect(() => {
        getAllCustomer()
    }, [])

    const downloadImage1 = data => {
        saveAs(imgUrl + data.zipFile)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title="Right Agent Group"
                        breadcrumbItem="Loan View"
                    />
                    <Row className="mb-3">
                        <Col md="12">
                            <div>
                                <Button
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                    color="primary"
                                    outline
                                    style={{ float: "right" }}
                                >
                                    Back <i className="bx bx-left-arrow-alt"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Name: </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.name}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Phone : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.mobile}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Date Of Birth : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.dateOfBirth}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>State : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.state}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>City : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.city}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Gender : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.gender}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Type of Employment : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.employmentType}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Purpose Of Loan : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.loanPurpose}
                                                </span>
                                            </span>
                                        </Col>

                                        {/* <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Type Of Residence : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.typeOfResidence}
                                                </span>
                                            </span>
                                        </Col> */}
                                        <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Residential Status : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.residentialStatus}
                                                </span>
                                            </span>
                                        </Col>
                                        {customer.residentialStatus == "Non_resident"?(
                                            <Col className="mb-3" md="3">
                                            {" "}
                                            <span>
                                                <b className="text-muted" style={{ fontSize: "16px" }}>Country Name  : </b>{" "}<br />
                                                <span style={{ fontSize: "16px" }}>
                                                    {customer.country}
                                                </span>
                                            </span>
                                        </Col>
                                        ):(
                                           ""
                                        )}
                                        
                                        {customer.takenLoan == "Yes" ? (
                                            <>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Have you taken loan : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.takenLoan}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Date on Loan taken : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.dateOfLoanTaken}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Tenure of loan (in months) : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.loanTenure}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Financial Institution : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.financialInstitute}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Outstanding Balance : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.outStandingBalannce}
                                                        </span>
                                                    </span>
                                                </Col>

                                            </>
                                        ) : ""}

                                        {customer.employmentType == "Salaried" ? (
                                            <>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Company Name : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.companyName}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Company Type : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.companyType}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Take Home Salary : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.takeHomeSalary}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Variable Monthly Income : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.variableMontlySalary}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Other Monthly Income : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.otherMonthlySalary}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Age Of Retirement : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.retrirementAge}
                                                        </span>
                                                    </span>
                                                </Col>
                                                {/* <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>EMI's Existing loans : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.existingEMIPay}
                                                        </span>
                                                    </span>
                                                </Col> */}
                                            </>
                                        ) : ""}

                                        {customer.employmentType == "Business" ? (
                                            <>

                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>Other Monthly Income : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.otherMonthlySalary}
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col className="mb-3" md="3">
                                                    {" "}
                                                    <span>
                                                        <b className="text-muted" style={{ fontSize: "16px" }}>EMI's paid towards existing loans : </b>{" "}<br />
                                                        <span style={{ fontSize: "16px" }}>
                                                            {customer.existingEMIPay}
                                                        </span>
                                                    </span>
                                                </Col>
                                            </>
                                        ) : ""}




                                        {/* <Col className="mb-3" md="3"> <span><b style={{fontSize:"16px"}}>Company Name :</b>  <span style={{fontSize:"16px"}}>{customer.workingCompany}</span></span></Col>  
                 <Col className="mb-3" md="3"> <span><b style={{fontSize:"16px"}}>Designation : </b> <span style={{fontSize:"16px"}}>{customer.designation}</span></span></Col>  
                 <Col className="mb-3" md="3"> <span><b style={{fontSize:"16px"}}>Length of Service : </b> <span style={{fontSize:"16px"}}>{customer.serviceLength}</span></span></Col>  
                 <Col className="mb-3" md="3"> <span><b style={{fontSize:"16px"}}>Annual Income : </b> <span style={{fontSize:"16px"}}>{customer.annualIncome}</span></span></Col>  
                 <Col className="mb-3" md="3"> <span><b style={{fontSize:"16px"}}>Spouse Name :</b>  <span style={{fontSize:"16px"}}>{customer.spouseName}</span></span></Col>  
                 <Col className="mb-3" md="3"> <span><b style={{fontSize:"16px"}}>Spouse DOB : </b> <span style={{fontSize:"16px"}}>{customer.spouseDoB}</span></span></Col>   */}
                                    </Row>
                                    <h5 className="mt-3 mb-3">Documents</h5>

                                    <Row>
                                        {customer.itReturn == "" || customer.itReturn == undefined ? (
                                            ""
                                        ) : (
                                            <Col className="mb-5" md="4">
                                                <p>It Return</p>

                                                <iframe
                                                    src={imgUrl + customer.itReturn}
                                                    style={{ width: "200px" }}
                                                />
                                            </Col>
                                        )}
                                        {customer.aadharCard == "" || customer.aadharCard == undefined ? (
                                            ""
                                        ) : (
                                            <Col className="mb-5" md="4">
                                                <p>Aadhar Card</p>

                                                <iframe
                                                    src={imgUrl + customer.aadharCard}
                                                    style={{ width: "200px" }}
                                                />
                                            </Col>
                                        )}
                                        {customer.panCard == "" || customer.panCard == undefined ? (
                                            ""
                                        ) : (
                                            <Col className="mb-5" md="4">
                                                <p>Pan Card</p>
                                                <iframe
                                                    src={imgUrl + customer.panCard}
                                                    style={{ width: "200px" }}
                                                />
                                            </Col>
                                        )}
                                        {customer.bankStatement == "" || customer.bankStatement == undefined ? (
                                            ""
                                        ) : (
                                            <Col className="mb-5" md="4">
                                                <p>Bank Statement</p>
                                                <iframe
                                                    src={imgUrl + customer.bankStatement}
                                                    style={{ width: "200px" }}
                                                />
                                            </Col>
                                        )}
                                        {customer.salarySlip == "" || customer.salarySlip == undefined ? (
                                            ""
                                        ) : (
                                            <Col className="mb-5" md="4">
                                                <p>Salary Slip</p>
                                                <iframe
                                                    src={imgUrl + customer.salarySlip}
                                                    style={{ width: "200px" }}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <div>
                                        <Button
                                            onClick={() => {
                                                downloadImage1(customer)
                                            }}
                                            color="primary"
                                            outline
                                            style={{ float: "right", width: "150px" }}
                                        >
                                            Download <i className="bx bx-cloud-download"></i>
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default InsuranceForm


